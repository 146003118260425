/*----------------------------------------------------------------------------*\
    Headhesive Specific Styles
/*----------------------------------------------------------------------------*/

/**
 * Headhesive element clone
 * > `clone` class for the cloned element:
 *
 |  var options = {
 |      classes {
 |          clone: 'banner--clone';
 |      }
 |  }
 *
 */
.banner--clone {

    /* Required styles */
    position: fixed;
    top: 0;
    left: 0;

    /* Additional styles */
    background: #faca3a; 

    /* Translate -100% to move off screen */
    -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
            transform: translateY(-100%);

    /* Animations */
    -webkit-transition: all 300ms ease-in-out;
       -moz-transition: all 300ms ease-in-out;
            transition: all 300ms ease-in-out;

}


/**
 * Headhesive stick
 * > `stick` class for the cloned element:
 *
 |  var options = {
 |      classes {
 |          stick: 'banner--stick';
 |      }
 |  }
 *
 */
.banner--stick {

    /* Translate back to 0%; */
    -webkit-transform: translateY(0%);
        -ms-transform: translateY(0%);
            transform: translateY(0%);
}


/**
 * Headhesive unstick
 * > `unstick` class for the cloned element:
 *
 |  var options = {
 |      classes {
 |          unstick: 'banner--unstick';
 |      }
 |  }
 *
 */
.banner--unstick {
    /* Not required to use, but could be useful to have */
	background:url(../img/blockquote-img.png);
	background-color: #000;
}
